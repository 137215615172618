import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessSuggestion, NewSuggestion, RespBusinessSuggestion } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {

  constructor(private http: HttpClient) { }

  // getNuoviSuggestion() {
  //   let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
  //   return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/new/false`, { headers }).pipe(
  //     map((data: RespBusinessSuggestion) => {
  //       return data;
  //     })
  //   );
  // }

  //------------------------------------------------------

  getNuoviSuggestionNew() {
    let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/inactive/false`, { headers }).pipe(
      map((data: RespBusinessSuggestion) => {
        return data;
      })
    );
  }

  getRespintiSuggestionNew() {
    let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/rejected/false`, { headers }).pipe(
      map((data: RespBusinessSuggestion) => {
        return data;
      })
    );
  }

  getApprovatiSuggestionNew() {
    let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/active/false`, { headers }).pipe(
      map((data: RespBusinessSuggestion) => {
        return data;
      })
    );
  }

  getRevisioneNew() {
    let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/review/false`, { headers }).pipe(
      map((data: RespBusinessSuggestion) => {
        return data;
      })
    );
  }



//---------------------------------------
  // getRespintiSuggestion() {
  //   let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
  //   return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/inactive/false`, { headers }).pipe(
  //     map((data: RespBusinessSuggestion) => {
  //       return data;
  //     })
  //   );
  // }

  // getApprovatiSuggestion() {
  //   let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
  //   return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/active/false`, { headers }).pipe(
  //     map((data: RespBusinessSuggestion) => {
  //       return data;
  //     })
  //   );
  // }

  setImage(file: File, path: string) {
    let data = new FormData()
    data.append('file', file)
    data.append('path', 'passaparola/busines-suggestions/' + path)
    let headers = new HttpHeaders({ 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` });
    return this.http.post<string>(`${environment.endpoint}storage`, data, { headers }).pipe(
      map((data: string) => {
        return data;
      })
    );
  }

  newSuggestion(data: NewSuggestion) {
    let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.post<any>(`${environment.endpoint}business-suggestion`, data, { headers }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  //-------------------------------------------------------------
  updateSuggestionStatus(id: string, isActive: boolean): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` });

    // Assicurati di includere l'ID nel percorso
    const url = `${environment.endpoint}business-suggestion/${id}`;  // Inserisci l'ID nella URL

    // Corpo della richiesta
    const body = { isActive };

    return this.http.patch<any>(url, body, { headers }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  //-------------------------------------------------------------


  updateSuggestionStatusAndFlag(id: string, isActive: boolean, status: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`
    });

    // Assicurati di includere l'ID nel percorso
    const url = `${environment.endpoint}business-suggestion/${id}`; // Inserisci l'ID nella URL

    // Corpo della richiesta
    const body = { isActive, status };

    return this.http.patch<any>(url, body, { headers }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


  updateSuggestionStatusApproved(id: string, isActive: boolean, status: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`
    });

    // Assicurati di includere l'ID nel percorso
    const url = `${environment.endpoint}business-suggestion/${id}`; // Inserisci l'ID nella URL

    // Corpo della richiesta
    const body = { isActive, status };

    return this.http.patch<any>(url, body, { headers }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateSuggestionStatusReview(id: string, isActive: boolean, status: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`
    });

    // Assicurati di includere l'ID nel percorso
    const url = `${environment.endpoint}business-suggestion/${id}`; // Inserisci l'ID nella URL

    // Corpo della richiesta
    const body = { isActive, status };

    return this.http.patch<any>(url, body, { headers }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


  //-------------------------------------------------------------

  updateSuggestionDetails(id: string, isActive: boolean, status: boolean): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`
    });

    const url = `${environment.endpoint}business-suggestion/${id}`;

    const body = { isActive, status };

    return this.http.patch<any>(url, body, { headers }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }



getSuggestionById(id: string): Observable<BusinessSuggestion> {
  let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` });

  // URL per ottenere il suggerimento tramite ID
  const url = `${environment.endpoint}business-suggestion/${id}`;

  return this.http.get<BusinessSuggestion>(url, { headers }).pipe(
    map((data: BusinessSuggestion) => {
      return data;
    })
  );
}



}
